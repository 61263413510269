$view-xs-size: 20em;

@mixin responsive($query) {
    @media (max-width: 37.5em) {
        @content;
    }
}

.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @include responsive($view-xs-size) {
        margin-top: 1rem !important;
    }
}

.avatar {
    margin-top: 0;
    justify-content: center;
    padding: 0.8rem;

    @include responsive($view-xs-size) {
        margin-top: 2rem;
        padding: 0.8rem 0.8rem 0.8rem 1.5rem;
    }
}

.textField {
    width: 45%;
    margin-top: 0 !important;
    margin-bottom: 1rem !important;

    @include responsive($view-xs-size) {
        width: 100%;
    }
}

.phoneData {
    @extend .fullWidth;

    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
}

.countryCode {
    width: 25%;
    text-align: center;

    @include responsive($view-xs-size) {
        margin-bottom: 1rem !important;
        width: 100%;
    }
}

.phoneNumber {
    width: 72%;

    @include responsive($view-xs-size) {
        margin-bottom: 1rem !important;
        width: 100%;
    }
}

.fullWidth {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
    width: 95%;

    @include responsive($view-xs-size) {
        width: 100%;
    }
}

.fullWidth::-ms-clear,
.fullWidth::-ms-reveal {
    display: none !important;
}

.button {
    width: 80%;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    padding: 1rem !important;

    @include responsive($view-xs-size) {
        margin-top: 1rem !important;
        width: 100%;
    }
}

.login {
    justify-content: center;
}

.box {
    margin-top: 0 !important;
}

.helperDanger {
    color: #f44336;
}

.buttonWrapper {
    align-items: center;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    margin-top: 1rem;
}

.forgotText {
    text-align: right;

    @include responsive($view-xs-size) {
        text-align: center;
    }
}

.countryCodeItem {
    width: 17%;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;

    div,
    label {
        font-size: 15px !important;
    }

    @include responsive($view-xs-size) {
        margin-top: 0.5rem !important;
        width: 95%;
    }
}

.countryCodeOptions {
    font-size: 16px !important;
    display: flex;
    justify-content: space-between;

    .countryCodeValue {
        width: 25%;
        display: flex;
        justify-content: flex-start;
    }

    .countryCodeCountry {
        width: 70%;
        display: flex;
        justify-content: flex-end;
    }
}
